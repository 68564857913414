<template>
    <div>
        <transition name="slide" mode="out-in">
            <p :key="currentIndex" class="news-item">{{ currentNews }}</p>
        </transition>
    </div>
</template>

<script setup>

const props = defineProps({
    news: {
        type: Array,
        required: true
    }
})

const currentIndex = ref(0);

const currentNews = computed(() => props.news[currentIndex.value]);

const updateNews = () => {
    currentIndex.value = (currentIndex.value + 1) % props.news.length;
};

let interval;
onMounted(() => {
    interval = setInterval(updateNews, 6000);
});

onBeforeUnmount(() => {
    clearInterval(interval);
});

</script>

<style scoped>
.news-item {
  transition: transform 0.5s;
}
.slide-enter-active, .slide-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}
.slide-enter-from {
  opacity: 0;
  transform: translateY(100%);
}
.slide-enter-to {
  opacity: 1;
  transform: translateY(0);
}
.slide-leave-from {
  opacity: 1;
  transform: translateY(0);
}
.slide-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}
</style>